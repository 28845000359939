@import '../../../assets/css/shared/variables';

.contact-form {
  padding-top: 2em;

  .detail {
    display: flex;
    flex-direction: column;

    .input-field {
      display: flex;
      flex: 1;
      flex-direction: column;

      &:last-child {
        padding-right: 0; } }

    input {
      border: 1px solid $light-grey;
      box-shadow: none;
      flex: 1;
      margin-bottom: 1em;
      padding: 1.25em 1em;

      &:focus {
        border-color: $light-indigo;
        outline: none; } } }

  .message {
    display: flex;
    flex-direction: column;

    textarea {
      border: 1px solid $light-grey;
      flex: 1;
      margin-bottom: 2em;
      padding: 1em;

      &:focus {
        border-color: $light-indigo;
        outline: none; } } }


  .error {
    color: $red;
    padding-bottom: .25em; }

  button {
    &.action {
      background: linear-gradient(-45deg, $indigo, $light-indigo);
      border: 0;
      border-radius: 1.5em;
      color: $white;
      font-size: 1.05em;
      font-weight: bold;
      padding: 1em 2em;
      text-align: center;
      text-transform: uppercase;

      &:disabled {
        background: $dark-grey;

        &:hover {
          cursor: not-allowed; } }

      &:hover {
        &:enabled {
          background: $dark-indigo;
          color: $white;
          cursor: pointer; } } } } }

@media screen and (min-width: 992px) {
  .contact-form {
    .detail {
      align-items: flex-end;
      flex-direction: row;

      .input-field {
        padding-right: 1.25em; }

      input {
        margin-right: 2em;

        &:last-child {
          margin-right: 0; } } }

    .action {
      display: inline-flex; } } }
