@import '../../assets/css/shared/variables';

.contact {
  background-color: $off-white;
  padding: 2em;

  .container {
    margin: auto;
    max-width: 40em; }

  .prose {
    .statement {
      font-size: 2em;
      font-weight: bold;
      margin: auto;
      max-width: 21em;
      padding-bottom: .75em;
      text-align: center; }

    .core {
      color: $grey;
      font-size: 1.05em;
      line-height: 2em;
      margin: auto;
      max-width: 40em;
      text-align: center; } } }

@media screen and (min-width: 992px) {
  .contact {
    .container {
      max-width: 80em; } } }
