$base-font-size: 14px;

$white: #fff;
$off-white: #f7f7f7;
$dark-off-white: darken($off-white, 10%);

$indigo: #4e54c8;
$light-indigo: lighten($indigo, 15%);
$dark-indigo: darken($indigo, 10%);
$transparent-indigo: rgba(78, 84, 200, .95);
$transparent-light-indigo: rgba(128, 137, 255, .9);

$very-light-grey: #eee;
$light-grey: #d1d1d1;
$grey: #808080;
$dark-grey: #636363;
$very-dark-grey: #191919;

$red: #7b0004;

$black: #000;



