html {
  height: 100%; }

body {
  display: flex;
  flex-direction: column;
  font: normal $base-font-size 'Open Sans', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  height: 100%;

  .root {
    height: 100%; }

  .site {
    display: flex;
    flex-direction: column;
    height: 100%; } }

header {
  flex: none; }

main {
  display: flex;
  flex: 1;
  flex-direction: column; }
