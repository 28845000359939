@import '../../../assets/css/shared/variables';

.about {
  background-color: $off-white;
  padding: 3em 2em 2em;

  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 40em; }

  .image {
    padding-bottom: 1em;
    text-align: center;

    img {
      max-width: 40em;
      width: 100%; } }

  .statement {
    font-size: 2em;
    font-weight: bold;
    margin: auto;
    max-width: 21em;
    padding-bottom: .75em; }

  .core {
    color: $grey;
    font-size: 1.05em;
    line-height: 2em;
    margin: auto;
    max-width: 40em;
    padding-bottom: 2em; }

  .action {
    background: linear-gradient(-45deg, $indigo, $light-indigo);
    border-radius: 1.5em;
    color: $white;
    display: inline-flex;
    font-weight: bold;
    margin: .5em;
    padding: 1em 2em;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      background: $dark-indigo;
      color: $white;
      cursor: pointer; } } }

@media screen and (min-width: 992px) {
  .about {
    padding: 5em 2em 4em;

    .container {
      flex-direction: row;
      max-width: 80em; }

    .image {
      padding-right: 2em; }

    .prose {
      padding-left: 2em;
      padding-right: 1em; } } }
