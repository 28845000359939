html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15; }
body {
  margin: 0; }

main {
  display: block; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

pre {
  font-family: monospace, monospace;
  font-size: 1em; }

a {
  background-color: transparent; }

abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  text-decoration: underline dotted; }

b, strong {
  font-weight: bolder; }

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
 * ========================================================================== */

/**
 * Remove the border on images inside links in IE 10. */

img {
  border-style: none; }

/* Forms
 * ========================================================================== */

/**
 * 1. Change the fonts styles in all browsers.
 * 2. Remove the margin in Firefox and Safari. */

button, input, optgroup, select, textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0; }
/* 2 */

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge. */

button, input {
  /* 1 */
  overflow: visible; }

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox. */

button, select {
  /* 1 */
  text-transform: none; }

/**
 * Correct the inability to style clickable types in iOS and Safari. */

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button; }

/**
 * Remove the inner border and padding in Firefox. */

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Restore the focus styles unset by the previous rule. */

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

/**
 * Correct the padding in Firefox. */

fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers. */

legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal; }
/* 1 */

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera. */

progress {
  vertical-align: baseline; }

/**
 * Remove the default vertical scrollbar in IE 10+. */

textarea {
  overflow: auto; }

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10. */

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0; }
/* 2 */

/**
 * Correct the cursor style of increment and decrement buttons in Chrome. */

[type="number"] {
  &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
    height: auto; } }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari. */

[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  &::-webkit-search-decoration {
    -webkit-appearance: none; } }

/**
 * Remove the inner padding in Chrome and Safari on macOS. */

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change fonts properties to `inherit` in Safari. */

 ::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit; }
/* 2 */

/* Interactive
 * ========================================================================== */

/*
 * Add the correct display in Edge, IE 10+, and Firefox. */

details {
  display: block; }

/*
 * Add the correct display in all browsers. */

summary {
  display: list-item; }

/* Misc
 * ========================================================================== */

/**
 * Add the correct display in IE 10+. */

template, [hidden] {
  display: none; }

/**
 * Add the correct display in IE 10. */
