@import '../../../assets/css/shared/variables';

.projects {
  padding: 2em;

  .statement {
    padding-bottom: 1em; }

  .title {
    font-size: 2.5em;
    font-weight: bold;
    padding-bottom: .5em;
    text-align: center; }

  .description {
    color: $grey;
    font-size: 1.05em;
    line-height: 2em;
    margin: auto;
    max-width: 40em;
    text-align: center; }

  .contributions {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 80em;

    .contribution {
      background-color: $off-white;
      border-radius: .75em;
      color: $black;
      flex: 1;
      margin: 1em;
      padding: 2em;
      text-align: center;
      text-decoration: none;

      &:hover {
        background-color: $dark-off-white;
        cursor: pointer; }

      .icon {
        color: $indigo;
        font-size: 3em;
        padding-bottom: .375em; }

      .heading {
        font-size: 1.5em;
        font-weight: bold;
        padding-bottom: .5em; }

      .offer {
        color: $grey;
        font-size: 1.05em;
        line-height: 1.75em; } } } }

@media screen and (min-width: 992px) {
  .projects {

    .description {
      font-size: 1.125em;
      padding-bottom: 1.5em; }

    .contributions {
      flex-direction: row;

      .contribution {
        padding: 4em 2em;

        .icon {
          font-size: 5em; }

        .heading {
          font-size: 1.625em; }

        .offer {
          font-size: 1.125em; } } } } }

