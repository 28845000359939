@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('./../../assets/fonts/google/OpenSans-Light.woff2') format('woff2'), url('./../../assets/fonts/google/OpenSans-Light.woff') format('woff'); }

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  src: url('./../../assets/fonts/google/OpenSans-Regular.woff2') format('woff2'), url('./../../assets/fonts/google/OpenSans-Regular.woff') format('woff'); }

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  src: url('./../../assets/fonts/google/OpenSans-Bold.woff2') format('woff2'), url('./../../assets/fonts/google/OpenSans-Bold.woff') format('woff'); }

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: normal;
  src: url('./../../assets/fonts/google/OpenSans-Italic.woff2') format('woff2'), url('./../../assets/fonts/google/OpenSans-Italic.woff') format('woff'); }

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: bold;
  src: url('./../../assets/fonts/google/OpenSans-BoldItalic.woff2') format('woff2'), url('./../../assets/fonts/google/OpenSans-BoldItalic.woff') format('woff'); }
