@import '../../../assets/css/shared/variables';

header {
  background: linear-gradient(-45deg, $indigo, $light-indigo);
  color: $white;
  left: 0;
  padding: 1em;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;

  &.sticky {
    background: linear-gradient(-45deg, $indigo, $light-indigo);
    position: fixed; }

  .container {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    max-width: 540px;
    width: 100%; }

  .title {
    align-items: center;
    display: flex;
    flex: 1; }

  .brand {
    align-items: center;
    color: $white;
    display: flex;
    flex: 1;
    text-decoration: none;

    i {
      display: flex;
      font-size: 3em;
      padding-right: .125em; }

    .name {
      font-size: 2em;
      font-weight: bold;
      padding-left: .25em; } }

  .menu {
    background: $white;
    color: $indigo;
    font-size: 1.5em;
    padding: .25em .5em;

    &:hover {
      cursor: pointer; } }

  .navigation {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;

    &.closed {
      display: none; } }

  .links {
    display: flex;
    flex-direction: column;

    a {
      color: $white;
      padding-bottom: .5em;
      padding-right: 1em;
      padding-top: .5em;
      text-decoration: none;

      &.active {
        opacity: .5; }

      &:hover {
        cursor: pointer;
        opacity: .5; } } }

  .action {
    background: $white;
    border-radius: 1.5em;
    color: $indigo;
    margin: .5em;
    padding: 1em 2em;
    text-decoration: none;

    &:hover {
      background: $dark-indigo;
      color: $white;
      cursor: pointer; } } }


@media screen and (min-width: 601px) {
  header {
    background: transparent;

    .container {
      flex-direction: row; }

    .menu {
      display: none; }

    .navigation {
      display: flex;
      flex-direction: row;

      &.closed {
        display: flex; } }

    .links {
      flex-direction: row; } } }


@media screen and (min-width: 768px) {
  header {
    .container {
      max-width: 720px; }

    .links {
      a {
        padding-right: 2em; } } } }

@media screen and (min-width: 992px) {
  header {
    .container {
      max-width: 1140px; } } }



